@import '@assets/theme';

.CollapsedCriteria {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    &--criteria {
        display: flex;
        gap: 10px;
    }
}
