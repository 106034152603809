@import '@assets/theme';

.BadgeIcon {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 11px;

    svg {
        color: #fff;
    }

    &.size-small {
        height: 18px;
        width: 18px;
        border-radius: 7.3px;

        svg {
            height: 10px;
            width: 10px;
        }
    }

    &.size-medium {
        height: 28px;
        width: 28px;

        svg {
            height: 16px;
            width: 16px;
        }
    }

    &.size-large {
        height: 32px;
        width: 32px;

        svg {
            height: 18px;
            width: 18px;
        }
    }

    &--purple {
        background: $gradient_purple;
    }

    &--blue {
        background: $gradient_blue;
    }

    &--orange {
        background: $gradient_orange;
    }

    &--green {
        background: $gradient_green;
    }

    &--red {
        background: $gradient_red;
    }

    &--count {
        position: absolute;
        top: -7px;
        left: 19px;
        background-color: $color_primary_aqua;
        border-radius: 50%;
        border: 1px solid white;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 600;
        font-size: 10px;
        line-height: 8px;
        color: black;
    }
}
