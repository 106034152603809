@import '@assets/theme';

.DraggableList {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.ListItem {
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px 10px 16px;
    border: 1px solid $color_rgba_gray;
    border-radius: 8px;
    background: white;

    cursor: pointer;
    user-select: none;

    &--icon {
        padding: 0 4px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(.ListItem--icon__disabled) {
            cursor: pointer;
        }

        &:hover {
            .ListItem--icon__close {
                opacity: 1;
            }
        }

        &__close {
            opacity: 0.65;
            width: 8px;
        }

        &__drag {
            opacity: 0.5;
        }
    }

    &--name {
        display: flex;
        align-items: center;
        column-gap: 12px;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: $color_soft_black;
    }

    &--dragging {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border: 1px solid #66deb2;

        .ListItem--icon__drag {
            opacity: 1;
        }
    }

    &--disabled {
        background: rgba(0, 0, 0, 0.02);
        border: 1px solid $color_rgba_gray;
    }
}
