@import '@assets/theme';

.RadioGroup {
    display: flex;
}

.RadioGroup > * {
    margin-right: 20px;
}

.RadioGroup > *:last-child {
    margin-right: 0;
}
