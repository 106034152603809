@import '@assets/theme';

.Widget {
    $default-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 2px 16px rgba(0, 0, 0, 0.04);
    $default-radius: 24px;
    background-color: white;
    box-shadow: $default-shadow;
    border-radius: $default-radius;
    scroll-margin-top: 4px;


    &--primary {
        padding: 16px 32px 50px 32px;

        @include breakpoint(small) {
            border-radius: 20px;
        }
    }

    &--table {
        padding-top: 32px;
        padding-bottom: 24px;

        @include breakpoint(small) {
            border-radius: 20px;
            padding-top: 24px;
        }
    }

    &--modal {
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 24px 24px 16px 24px;
    }

    &--dropdown {
        border: 1px solid $color_rgba_gray;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 20px 0;
    }

    &--collapse {
        padding: 24px 32px;

        @include breakpoint(small) {
            padding: 24px 20px;
        }
    }

    &--section {
        padding: 32px;

        @include breakpoint(small) {
            padding: 24px 20px;
        }

        &.section-small {
            @include breakpoint(small) {
                padding: 16px;
            }
        }

        &.section-bottom {
            box-shadow: none;
            padding: 0;
            width: 100%;
        }

        &.criteria {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
