.TerritoryImport {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__header {
        margin-bottom: 20px;
    }

    &__input {
        margin-bottom: 15px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        max-width: 300px;
        box-sizing: border-box; 
        text-align: center;
        cursor: pointer;
    }

    &__error-message {
        margin-top: 10px;
        color: red;
        font-size: 14px;
    }

    &__success-message {
        margin-top: 10px;
        color: green;
        font-size: 14px;
    }
}
