@import '@assets/theme';

.OperatorProfile {
    &__customer-status {
        display: flex;
        flex: 1;
        margin-left: 12px;
    }

    &__header {
        display: flex;
        justify-content: space-between;

        @include breakpoint(medium) {
            flex-direction: column;
        }
    }

    &__header-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 12px;
    }

    &__mobile-divider {
        display: none;

        @include breakpoint(medium) {
            display: flex;
            width: calc(100% + 40px);
            margin-left: -20px;
            height: 1px;
            background-color: rgba(85, 85, 85, 0.1);
            margin-bottom: 16px;
        }
    }

    &__profile-info {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 12px;

        @include breakpoint(medium) {
            flex-wrap: wrap;
        }
    }

    &__profile-links {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 20px;

        @include breakpoint(medium) {
            flex-basis: 100%;
            margin: 16px 0 0 0;
        }
    }

    &__profile-tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        gap: 8px;

        @include breakpoint(medium) {
            margin-top: 12px;
            margin-bottom: 24px;
        }
    }

    &__heading-description {
        display: flex;
        align-items: center;
        column-gap: 4px;
        color: $color_opaque_soft_black;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        margin-bottom: 5px;

        svg {
            height: 18px;
        }
    }

    &__info-column-skeleton {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    &__pagetitle_skeleton {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__location_skeleton {
        margin-top: 12px;
        margin-bottom: 40px;
    }

    &__location_container_skeleton {
        display: flex;
        column-gap: 32px;
        row-gap: 24px;

        @include breakpoint(medium) {
            flex-direction: column;
        }
    }

    &__data_section_skeleton {
        display: flex;
        width: 60%;
        flex-direction: column;
    }

    &__info_skeleton {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 60px;

        @include breakpoint(medium) {
            grid-template-columns: 1fr;
        }
    }

    &__map-section_skeleton {
        display: flex;
        height: 260px;
        width: 100%;
        max-width: 600px;

        @include breakpoint(medium) {
            min-width: unset;
            max-width: unset;
        }
    }

    &__map_container_skeleton {
        height: 100%;
        width: 100%;
        border-radius: 12px;
        position: relative;
    }

    &__map_content_skeleton {
        height: 100%;
        width: 100%;
        border-radius: 12px;
    }
    &__separator_skeleton {
        background-color: #5555551a;
        height: 1px;
        margin: 24px 0;
        width: 100%;
    }
}
