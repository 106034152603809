@import '@assets/theme';

.GroupedBarChart {
    $root: &;
    $default-spacing: 16px;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;

    @include thin-scrollbar;

    &__viewport {
        display: flex;
        flex: 1 0 auto;
        height: 100%;
    }

    &__group-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &:last-child {
            .GroupedBarChart__group:before {
                display: none;
            }
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 $default-spacing;
        border-bottom: 1px solid#e4e4e4;

        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 0;
            position: absolute;
            right: 0;
            border-right: 1px dashed #fff;
            background:
                linear-gradient(#fff 0 0) padding-box,
                linear-gradient(to bottom, #fafafa, #dddddd 30%) border-box;
        }

        #{$root}__group-title {
            color: $color_soft_black;
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;
            margin-bottom: $default-spacing;
            height: 32px;
        }
    }

    &__bars-container {
        display: flex;
        position: relative;
        gap: 16px;
        height: 160px;

        align-items: flex-end;
        justify-content: space-evenly;
    }

    &__grid-line {
        position: absolute;
        width: calc(100% + 32px);
        height: 1px;
        z-index: 2;
        background: rgba(85, 85, 85, 0.04);
    }

    &__bar {
        cursor: pointer;
        position: relative;
        background-size: 100%;
        background-image: linear-gradient(180deg, #00b4ff 0%, #07c7f9 35.5%, #1dfeec 100%);
        width: 24px;
        z-index: 20;
        border-radius: 4px 4px 0px 0px;

        &:before {
            opacity: 0;
            transition: opacity 0.3s;
            background-color: #d9d9d9;
            content: '';
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            z-index: 10;
            border-radius: 4px 4px 0px 0px;
        }

        transition: border-radius 0s;
        &.dimmed {
            transition: border-radius 0.6s;
            border-radius: 6px 6px 0 0;

            &:before {
                opacity: 1;
            }
        }
    }

    &__group-labels {
        display: flex;
        margin-top: 8px;
        padding: 0 $default-spacing;
        column-gap: 16px;
        justify-content: space-evenly;
    }

    &__group-label {
        writing-mode: vertical-rl;
        transform: rotate(180deg);

        width: 24px;
        color: $color_opaque_soft_black;
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
    }
}
