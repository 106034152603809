@import '@assets/theme';

.Button {
    @mixin base-button-styles($color) {
        background: $color;
        padding: 12px 24px;
        border-radius: 100px;
    }

    @mixin tertiary-button-styles() {
        background: transparent;
        padding: 11px 14px;
        border: 1px solid rgba(85, 85, 85, 0.12);
        border-radius: 8px;
        font-weight: 500;
    }

    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    border: none;
    color: black;
    transition: background-color 300ms;
    white-space: nowrap;
    font-weight: 600;
    text-decoration: none;

    &--primary {
        @include base-button-styles($color_primary_aqua);
        box-shadow: 0px 2px 8px rgba(102, 222, 178, 0.2);

        &:hover {
            background: #52cda4;
        }
    }

    &--secondary {
        @include base-button-styles(#eeeeee);
        color: black;
    }

    &--tertiary {
        @include tertiary-button-styles();
        color: black;

        &:hover {
            border-color: $color_primary_aqua;
        }
    }

    &--dropdown {
        @include tertiary-button-styles();
        color: $color_soft_black;

        &:hover {
            border-color: rgba(85, 85, 85, 0.24);
        }

        &.active {
            border-color: $color_primary_aqua;
        }
    }

    &--naked {
        background: transparent;
        color: $tertiary_compliant_aqua;
        font-size: 13px;
        font-weight: 500;
        padding: 0;

        &:hover {
            color: black;
        }

        &.caret {
            color: #706e6e;

            &:hover {
                color: $color_soft_black;
            }
        }
    }

    &--disabled {
        background: #eeeeee;
        color: #b1b1b1;
        pointer-events: none;
        box-shadow: none;

        &.Button--tertiary,
        &.Button--dropdown,
        &.Button--naked {
            background: transparent;
        }
    }

    &--auto {
        width: auto;
    }

    &--error-border {
        border-color: $tertiary_ferrari_red;
    }

    &--success-border {
        border-color: $color_primary_aqua;
    }
}
