@import '@assets/theme';

.GeneralCriteria {
    scroll-margin-top: 40px;

    &__section {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        margin-top: 12px;
    }

    &__range-title {
        margin-bottom: 8px;
    }

    &__input-container {
        width: 47%;

        @include breakpoint(medium) {
            width: 100%;
            max-width: none;
        }
    }
}
