@import '@assets/theme';

.OperatorTargetingResults {
    &__header {
        margin-bottom: 40px;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 16px;

        @include breakpoint(small) {
            flex-basis: 100%;
            margin-top: 16px;
            justify-content: space-between;
        }
    }

    &__naked-button {
        flex-grow: 1;
        justify-content: flex-start;
        padding-left: 10px;
    }
}

.OperatorResultsTab {
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;

    &__content {
        display: flex;
        align-items: center;
        column-gap: 8px;
    }

    &__search-wrapper {
        width: 300px;
        margin-top: 8px;
    }

    &__button {
        flex: 1;
        column-gap: 6px;
        background-color: white;
    }

    &__button-loader {
        @extend %loading-spinner;

        width: 18px;
        height: 18px;
        margin-right: 4px;

        &::after {
            width: 10px;
            height: 10px;
        }
    }

    &__results-count {
        display: flex;
        flex-shrink: 0;
        font-size: 22px;
        line-height: 27px;
        font-weight: 700;
        color: $color_soft_black;
    }

    &__results-count-numbers {
        padding-right: 10px;
    }

    @include breakpoint(small) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;

        .OperatorResultsTab__results-count {
            order: -1;
        }

        &__content {
            flex-direction: column;
            width: 100%;
            row-gap: 12px;
            flex: 1;
        }

        &__search-wrapper {
            width: 100%;
        }
    }
}
