@import '@assets/theme';

.OperatorTargetingSearchHeader {
    $border: 1px solid rgba(#555, 0.08);

    background: #fff;
    padding: 0 40px;
    overflow: visible;
    flex-shrink: 0;
    border-bottom: $border;

    @include breakpoint(small) {
        padding: 0 20px;
    }

    &__section {
        padding: 34px 0 24px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-child(n + 2) {
            border-top: $border;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--secondary {
            padding: 16px 0;
            position: relative;
        }

        @include breakpoint(small) {
            flex-direction: column;
            padding: 24px 0;
            row-gap: 16px;

            &--secondary {
                row-gap: 24px;
                align-items: flex-start;
            }
        }
    }

    &__results {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-self: flex-start;
        column-gap: 24px;
        flex: 1 0 auto;
        margin-left: 20px;

        @include breakpoint(small) {
            margin-left: 0;
            justify-content: space-between;
            align-self: stretch;
            padding-top: 16px;
            border-top: $border;
            align-items: center;
        }
    }

    &__operators-count {
        display: flex;
        align-items: center;
        column-gap: 10px;
        color: $color_opaque_soft_black;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.26px;
        text-transform: uppercase;

        @include breakpoint(small) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__loading-spinner {
        @extend %loading-spinner;

        &.hidden {
            opacity: 0;
        }
    }

    &__operators-count-numbers {
        font-size: 22px;
        color: $color_soft_black;
        font-weight: 700;
        letter-spacing: 1px;
    }
}
