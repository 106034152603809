@import '@assets/theme';

.RadiusCriteria {
    &__actions-container {
        display: flex;
        margin-top: 24px;
        column-gap: 16px;
    }

    &__container-select-radius {
        flex: 1;
        max-width: 162px;

        @include breakpoint('medium') {
            max-width: none;
        }
    }

    &__map-container {
        display: flex;
        flex: 1 1 auto;
        height: 360px;
        max-width: 900px;
    }

    &__drag-banner {
        user-select: none;
        position: absolute;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;

        padding: 6px 10px;
        text-align: center;
        border-radius: 8px;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));

        color: $color_soft_black;
        font-size: 11px;
        font-weight: 500;
        line-height: 12px;
    }
}
