@import '@assets/theme';

.OperatorTargetingCriteria {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    $v-padding: 30px;
    $border: 1px solid rgba(#555, 0.08);
    $default-breakpoint: large;

    @include breakpoint($default-breakpoint) {
        bottom: auto;
    }

    &__main {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        max-height: 100%;
        overflow-y: hidden;

        @include breakpoint($default-breakpoint) {
            flex-direction: column;
        }
    }

    &__sidebar {
        border-right: $border;
        flex: 0 0 252px;
        overflow-y: auto;
        background-color: white;
        @include thin-scrollbar;

        @include breakpoint($default-breakpoint) {
            flex: 0 0 auto;
            border-bottom: $border;
            border-right: unset;
        }
    }

    &__content {
        padding: 40px;
        overflow-y: auto;
        flex: 0 1 100%;
        scrollbar-gutter: stable;

        @include breakpoint($default-breakpoint) {
            padding: 32px 20px;
        }
    }
}

.CriteriaContent {
    scroll-margin-top: 4px;

    &.with-gap {
        margin-bottom: 48px;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px 12px;
        margin-bottom: 16px;

        &-search {
            margin-left: 20px;
            flex-basis: 200px;

            @include breakpoint(small) {
                margin-left: auto;
                flex-basis: 100%;
            }
        }

        .BadgeIcon {
            height: 32px;
            width: 32px;
        }
    }

    &__segment-button {
        display: flex;
        flex-direction: row;
    }

    &__subtitle {
        margin-bottom: 20px;
        padding-top: 18px;
    }

    &__section {
        margin-bottom: 10px;
        scroll-margin-top: 4px;
    }

    &__heading {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        color: $color_soft_black;
        flex-basis: 100%;
        margin-bottom: 12px;

        &--auto {
            flex-basis: auto;
        }

        &--section {
            font-size: 16px;
        }

        span {
            font-weight: 500;
        }
    }

    &__columns {
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;

        &.col-4 {
            gap: 32px 4%;
        }

        &.col-3 {
            gap: 32px 5%;
        }

        &.col-2 {
            gap: 32px 6%;
        }

        &.col-1 {
            gap: 32px 0;
        }

        &.col-header {
            gap: 10px 0;
        }

        &.no-mt {
            margin-top: 0;
        }
    }

    &__column {
        &.col-4 {
            width: 22%;
        }

        &.col-3 {
            width: 30%;
        }

        &.col-2 {
            width: 47%;
        }

        &.col-1 {
            width: 100%;
        }
    }

    &__segment-columns {
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        gap: 32px 4%;

        @include breakpoint(medium) {
            gap: 32px 6%;
        }

        @include breakpoint(small) {
            gap: 32px 0;
        }
    }

    &__segment-column {
        width: 22%;

        @include breakpoint(medium) {
            width: 47%;
        }
        @include breakpoint(small) {
            width: 100%;
        }
    }

    @mixin widget-divider-styles() {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        text-transform: uppercase;
        opacity: 0.65;
        color: $color_soft_black;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    &__horizontal-divider-title {
        @include widget-divider-styles();
        margin: 32px 0;

        &:before,
        &:after {
            content: '';
            display: block;
            opacity: 0.65;
            height: 1px;
            flex: 0 1 100%;
            background-color: $color_soft_black;
        }

        &:before {
            margin-right: 16px;
        }

        &:after {
            margin-left: 16px;
        }
    }

    &__vertical-divider-title {
        @include widget-divider-styles();
        flex-direction: column;
        width: 8%;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 1px;
            flex: 0 1 100%;
            background-color: #eeeeee;
        }

        &:before {
            margin-bottom: 8px;
        }

        &:after {
            margin-top: 8px;
        }
    }

    @include breakpoint(large) {
        &__horizontal-divider-title {
            flex-basis: 100%;
        }
    }
}
