@import '@assets/theme';

.SearchInput {
    width: 280px;

    @include breakpoint(small) {
        width: 100%;
    }

    @include breakpoint(medium) {
        width: 100%;
    }

    &__menu-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
    }

    &__highlight {
        background: #d2f6e8;
    }
}
