.Popover {
    &__paper {
        background: white;
        border: 1px solid rgba(85, 85, 85, 0.1);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
        border-radius: 10px;

        &--unscrollable {
            overflow-y: hidden;
        }
    }
}
