@import '@assets/theme';

.SearchNavigationMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 20px;
    background: white;
    position: relative;

    &__title {
        font-size: 15px;
        font-weight: 600;
        color: $color_soft_black;
    }

    &__popover {
        display: flex;
        flex-direction: column;
        padding: 2px 4px;
    }
}
