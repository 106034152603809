@import '@assets/theme';

.SearchNavigation {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 100%;
    position: relative;
    padding: 12px 0;

    $indent: 16px;
    $indent-item: 42px;
    $hover: rgba(0, 0, 0, 0.02);

    &__header {
        display: flex;
        padding-bottom: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }

    &__title {
        padding: 8px 0 8px $indent;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #3a3a3a;

        .active {
            cursor: pointer;
            &:hover {
                opacity: 0.85;
                transform: scale(1.01);
            }
        }
    }

    &__item-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__item {
        margin-top: 8px;
        display: flex;
        padding: 8px 12px 8px $indent;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &:hover {
            background: $hover;
        }

        &.lvl-1 {
            padding-left: $indent-item;
        }
    }

    &__item-text {
        flex: 1 0 0;
        color: $color_soft_black;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;

        &.lvl-0 {
            text-transform: uppercase;
            font-size: 14px;
        }

        &.is-active:not(.lvl-0) {
            font-weight: 600;
        }
    }

    &__item-caret {
        flex-shrink: 0;
        width: 9px;
        height: 9px;
        transition: transform 0.2s ease-in-out;
        transform: rotate(90deg);

        &.is-active {
            transform: rotate(180deg);
        }
    }

    &__subitems {
        gap: 8px;
    }

    &__filters {
        display: flex;
        margin-top: 4px;
        margin-left: $indent-item;
        flex-direction: column;
        gap: 8px;
        border-left: 2px solid #f2f2f2;
    }

    &__filter-item {
        position: relative;
        display: flex;
        flex: 1 0 0;
        align-items: center;
        align-self: stretch;
        padding: 6px 0px 6px $indent;

        font-size: 13px;
        font-weight: 500;
        line-height: 16px;

        &.is-active {
            font-weight: 600;
        }

        &:hover {
            cursor: pointer;
            background: $hover;

            .SearchNavigation__filter-indicator {
                display: block;
            }
        }
    }

    &__filter-indicator {
        display: none;
        position: absolute;
        left: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        background: $gradient_purple;

        &.is-active {
            display: block;
        }
    }
}
