@import '@assets/theme';

.ProfileDetails {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: auto;
    gap: 16px;

    @include breakpoint(medium) {
        gap: 12px;
    }

    &__section {
        display: flex;
        flex-direction: column;
        padding: 24px 20px 20px 20px;
        border-radius: 12px;
        border: 1px solid $color_rgba_gray;

        &.col-4 {
            grid-column: span 4;
        }

        &.col-3 {
            grid-column: span 3;
        }

        &.col-2 {
            grid-column: span 2;
        }

        &.col-1 {
            grid-column: span 1;
        }

        @include breakpoint(medium) {
            padding: 20px 16px;

            &.col-4,
            &.col-3,
            &.col-2,
            &.col-1 {
                grid-column: span 4;
            }
        }
    }

    &__section-title {
        display: flex;
        column-gap: 6px;
        margin-bottom: 24px;

        &-icon {
            width: 18px;
            height: 18px;
            color: $color_opaque_soft_black;
        }
    }

    &__section-subtitle {
        font-size: 12px;
        font-weight: 500;
        color: $color_opaque_soft_black;
    }

    &__business {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 24px;
    }

    &__business-info {
        flex: 1 1 25%;

        @include breakpoint(medium) {
            flex: 1 1 50%;
        }
    }

    &__service-list {
        padding-inline-start: 28px;
        margin-block-start: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px 20px;
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__popularity {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;

        padding: 20px;
        border-radius: 12px;
        background-color: #faf5ff;
    }

    &__rating-stars {
        display: flex;
        align-items: center;
        gap: 1px;
    }

    &__experience {
        display: flex;
        column-gap: 32px;
        row-gap: 24px;

        @include breakpoint(medium) {
            flex-wrap: wrap;
            flex-direction: column;
        }
    }

    &__experience-divider {
        display: flex;
        align-items: center;
        align-self: stretch;
        width: 1px;
        background: $color_rgba_gray;

        @include breakpoint(medium) {
            height: 1px;
            width: 100%;
        }
    }

    &__experience-reviews {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 16px;

        @include breakpoint(medium) {
            gap: 12px;
        }
    }

    &__experience-review-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        min-width: 120px;
        height: 120px;
        padding: 16px 0px;

        border-radius: 12px;
        background: rgba(135, 0, 255, 0.04);

        @include breakpoint(medium) {
            flex: 0 1 calc(50% - 6px);
            padding: 20px 24px;
            min-width: 100px;
        }
    }



    &__service-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        margin-bottom: 20px;

        @include breakpoint(medium) {
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }

    &__comma-layout-container {
        min-width: 0;
    }
}
