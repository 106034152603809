@import '@assets/theme';

$cell: Table__cell;
$sort-icon-width: 9px;
$icon-margin: 3px;

.Table {
    width: 100%;

    &__row {
        .#{$cell} {
            position: relative;
        }

        &:last-of-type {
            .#{$cell} {
                border-bottom: 1px solid transparent;
            }
        }

        &:hover {
            $row-border-color: rgba(102, 222, 178, 0.8);
            box-shadow: 0px 0px 12px 0px rgba(102, 222, 178, 0.12);

            .#{$cell} {
                background: rgba(102, 222, 178, 0.04);
                border-top: 1px solid $row-border-color;
                border-bottom: 1px solid $row-border-color;

                &:first-child {
                    border-left: 1px solid $row-border-color;
                }

                &:last-child {
                    border-right: 1px solid $row-border-color;
                }
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% - 40px);
            left: 20px;
            border-bottom: 1px solid $color_rgba_gray;
        }
    }

    &__head {
        .#{$cell} {
            cursor: pointer;
            user-select: none;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            text-transform: uppercase;
            white-space: nowrap;
            color: $color_opaque_soft_black;
            top: -45px;
            padding-top: 0;

            .Table__sort-icon {
                width: $sort-icon-width;
                opacity: 0;
                color: $color_soft_black;
                margin-right: $icon-margin;
            }

            &:hover:not(.#{$cell}--selected-DESC):not(.#{$cell}--selected-ASC):not(.#{$cell}--unsortable) {
                .Table__sort-icon {
                    opacity: 0.65;
                    transform: rotate(180deg);
                }
            }

            &--unsortable {
                cursor: default;
            }

            &--selected-DESC {
                .Table__sort-icon {
                    opacity: 1;
                }
            }

            &--selected-ASC {
                .Table__sort-icon {
                    opacity: 1;
                    transform: rotate(180deg);
                }
            }

            .Table__filter {
                padding: 4px;
                border-radius: 5px;
                margin-left: $icon-margin;
                height: 18px;
                width: 19px;
                display: inline-flex;

                &:hover:not(.Table__filter--active) {
                    background-color: #eee;

                    .Table__filter-icon {
                        color: $color_soft_black;
                    }
                }

                &--active {
                    background-color: $color_primary_aqua;

                    .Table__filter-icon {
                        color: $color_soft_black;
                    }
                }
            }
        }
    }

    &__link {
        position: absolute;
        inset: 0;

        display: flex;
        text-decoration: none;
        color: inherit;
    }
}

.#{$cell} {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $color_soft_black;
    padding: 16px 0;
    border: 1px solid transparent;

    strong,
    b {
        font-weight: 600;
    }

    $first-and-last-child-left-padding: 20px;

    &:first-child {
        padding-left: $first-and-last-child-left-padding;
        padding-right: 20px;
        max-width: 30rem;
        border-radius: 12px 0 0 12px;
    }

    &:last-child {
        padding-left: $first-and-last-child-left-padding;
        padding-right: 30px;
        border-radius: 0 12px 12px 0;
    }

    &--right {
        text-align: right;
    }

    &--clickable {
        cursor: pointer;
    }

    &--is-sortable {
        padding-left: $sort-icon-width + $icon-margin;

        &:first-child,
        &:last-child {
            padding-left: $sort-icon-width + $icon-margin + $first-and-last-child-left-padding;
        }
    }
}
