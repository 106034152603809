@import '@assets/theme';

.CloseButton {
    height: 24px;
    width: 24px;
    background-color: #f5f5f5;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
        background-color: $color_primary_aqua;
    }

    &.dark {
        background-color: #383838;

        path {
            stroke: #fff;
        }

        &:hover {
            background-color: $color_light_gray;
        }
    }
}
