@use 'sass:map';
@forward 'media-query';

$color_primary_aqua: #66deb2;
$color_light_gray: #555555;
$color_soft_black: #232020;
$color_opaque_soft_black: #706e6e;
$color_off_white: #f5f7fe;
$color_rgba_gray: rgba(85, 85, 85, 0.1);

$tertiary_turquoise: #00ebf5;
$tertiary_bolt_blue: #00b4ff;
$tertiary_blue: #006ef5;
$tertiary_medium_blue: #006ef5;
$tertiary_purple: #8700ff;
$tertiary_magenta: #d200d7;
$tertiary_pink: #eb0087;
$tertiary_red: #d2000a;
$tertiary_ferrari_red: #f52d00;
$tertiary_orange: #ff6900;
$tertiary_tangerine: #ff9600;
$tertiary_yellow: #ffcd00;
$tertiary_lime_green: #b9e600;
$tertiary_green: #5fb400;
$tertiary_forest_green: #328200;
$tertiary_compliant_aqua: #00866f;

$gradient__colors: (
    'purple': (
        #d200d7 0%,
        #8700ff 100%,
    ),
    'blue': (
        #00ebf5 0%,
        #006ef5 100%,
    ),
    'orange': (
        #ffcd00 0%,
        #ff6900 90.62%,
    ),
    'green': (
        #b9e600 0%,
        #5fb400 100%,
    ),
    'red': (
        #ff6900 0%,
        #d2000a 82.93%,
    ),
);

@function getGradient($color, $angle: 0deg) {
    @return linear-gradient($angle, map.get($gradient__colors, $color));
}

$gradient_purple: getGradient('purple');
$gradient_blue: getGradient('blue');
$gradient_orange: getGradient('orange');
$gradient_green: getGradient('green');
$gradient_red: getGradient('red');

$box_shadow_primary: 0px 2px 2px rgba(0, 0, 0, 0.04);

@mixin checkbox-icon {
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        border-radius: 1px;
        background: #fff;
        transform-origin: 50% calc(100% - 1px);
        top: 50%;
        left: 50%;
        margin-left: -2px;
    }

    &:before {
        height: 6px;
        margin-top: -2px;
        transform: rotate(-44deg);
    }

    &:after {
        height: 10px;
        margin-top: -6px;
        transform: rotate(41deg);
    }
}

@mixin thin-scrollbar {
    &::-webkit-scrollbar {
        width: 12px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-border-radius: 7px;
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-button {
        display: none;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

%loading-spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 2.5px solid;
    border-color: $color_primary_aqua $color_primary_aqua transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 2.5px solid;
        border-color: transparent $color_primary_aqua $color_primary_aqua;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes rotationBack {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
}
