@import '@assets/theme';

.ReviewBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    min-width: 120px;
    height: 120px;
    padding: 16px 0px;

    border-radius: 12px;
    background: rgba(135, 0, 255, 0.04);

    @include breakpoint(medium) {
        flex: 0 1 calc(50% - 6px);
        padding: 20px 24px;
        min-width: 100px;
    }

    &__name {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 13px;
        color: $color_soft_black;
        line-height: 18px;
    }

    &__rating {
        font-size: 22px;
        font-weight: 700;
        color: $color_soft_black;
    }

    &__review-count {
        font-size: 12px;
        font-weight: 500;
        color: $color_opaque_soft_black;
    }
}
