@import '@assets/theme';

.SelectCountry {
    min-width: 70vw;
    max-width: 606px;
    padding-top: 16px;

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-top: 28px;
        margin-bottom: 8px;
        row-gap: 4px;

        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #706e6e;

        strong {
            font-weight: 600;
            color: $color_soft_black;
        }
    }

    &__body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        gap: 12px;
        overflow-y: auto;
        max-height: 300px;
        padding: 10px;

        @include thin-scrollbar
    }

    &__selector {
        height: 42px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 11px 0;
        column-gap: 10px;

        background: rgba(0, 0, 0, 0.02);
        border: 1px solid $color_rgba_gray;
        border-radius: 8px;

        font-weight: 500;
        font-size: 14px;
        color: $color_soft_black;
        cursor: pointer;

        &:hover {
            background: #ffffff;
            border: 1px solid $color_primary_aqua;
            box-shadow: $box_shadow_primary;
            border-radius: 8px;
        }

        &.is-active {
            background: #ffffff;
            border: 1px solid $color_primary_aqua;
            box-shadow: $box_shadow_primary;
            border-radius: 8px;
        }
    }

    &__selector-check {
        position: absolute;
        width: 20px;
        height: 20px;
        right: calc(-20px / 2);
        top: calc(-20px / 2);
        background: $color_primary_aqua;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
