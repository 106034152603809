@import '@assets/theme';

.MenuTabs {
    &__back-to-top {
        --offset: 100px;

        position: sticky;
        bottom: 20px;
        margin: 0 20px 0 auto;
        border: 0;
        margin-top: calc(100vh + var(--offset));

        width: 25px;
        border-radius: 10px;
        font-size: 0;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color_primary_aqua;

        @include breakpoint(medium) {
            margin: 0 0 0 auto;
            width: 20px;
            height: 20px;
        }
    }
}

.MenuTabs .MuiTab__button {
    font-size: 14px;
}
