@import '@assets/theme';

.Pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(small) {
        flex-direction: column;
        row-gap: 24px;
    }
}

.PaginationButtons {
    .MuiPagination-ul {
        .MuiPaginationItem-root {
            height: 32px;
            min-width: 32px;
            margin: 0 4px;
            border: 1px solid $color_rgba_gray;
            border-radius: 12px;
            padding: 7.5px 11.5px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;

            &.MuiPaginationItem-ellipsis {
                border: unset;
            }
        }

        button:hover {
            background-color: transparent;
            border: 1px solid $color_primary_aqua;
            box-shadow: $box_shadow_primary;
        }

        .Mui-selected {
            background-color: $color_primary_aqua;
            border: 1px solid $color_primary_aqua;
            box-shadow: $box_shadow_primary;

            &:hover {
                background-color: $color_primary_aqua;
            }
        }
    }
}

.PaginationSelect {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #6d6b6e;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    &__select {
        min-width: 84px;
        margin: 0 8px;
    }

    strong {
        color: $color_soft_black;
        font-weight: 600;
    }
}
