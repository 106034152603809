@import '@assets/theme';

.VerticalProgressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    justify-content: center;
    padding-top: 32px;

    &__container {
        height: 160px;
        width: 3px;
        border-radius: 12px;
        background-color: #e9e9e9;
        position: relative;
    }

    &__bar {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0%;
        background: linear-gradient(180deg, #00b4ff 100%, #00ebf5 100%),
            linear-gradient(180deg, #00a5a5 100%, #00e1af 100%), linear-gradient(180deg, #006ef5 100%, #00b4ff 100%);
        border-radius: 0 0 12px 12px;
        &.disabled {
            background: #d9d9d9;
            opacity: 0.3;
        }
    }

    &__bubble {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        min-width: 40px;
        width: max-content;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
        background:
            linear-gradient(#f5fcff, #f5fcff) padding-box,
            linear-gradient(#016ef5, #01b4ff) border-box;
        border-radius: 50px;
        border: 1px solid transparent;
        &.disabled {
            background: #f8f9f9;
            border-color: #d9d9d9;
        }
    }

    &__bubble-text {
        color: $color_soft_black;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
        &.disabled {
            color: #d9d9d9;
        }
    }

    &__bubble-text--clickable {
        cursor: pointer;
    }

    &__title {
        color: $color_opaque_soft_black;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
    }
}
