@import '@assets/theme';

.Home {
    position: absolute;
    inset: 0;
    overflow: hidden;

    &__overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }
    &__content {
        position: relative;
        z-index: 12;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__search {
        margin-top: 33vh;
        width: 100%;
        max-width: 720px;
        transition: margin 500ms;

        &--interacted {
            margin-top: 6vh;
        }
    }

    &__carousel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity 1s;
    }

    &__image--visible {
        opacity: 1;
    }

    &__content {
        position: relative;
        z-index: 2;
    }
}
