@import '@assets/theme';

.Tabs {
    width: 100%;

    .MuiTabs {
        &__root {
            min-height: auto;
        }

        &__flex-container {
            border-bottom: 1px solid #e5e7ed;
            height: 100%;
        }
    }

    .MuiTab {
        &__button {
            color: #8b8b8f;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            text-transform: capitalize;

            padding: 0px;
            align-items: flex-start;
            justify-content: flex-start;
            min-width: auto;
            min-height: auto;
			max-width: 900px;
            height: 37px;
            margin-right: 32px;

            &:hover {
                color: $color_light_gray;
            }

            &.MuiTab__selected {
                color: $color_soft_black;
            }

            &--secondary {
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #232020;
            }
        }
    }

    &__panel {
        padding-top: 32px;

        @include breakpoint(medium) {
            margin-bottom: 24px;
        }
    }

    &--base {
        .MuiTabs {
            &__root {
                padding: 16px 22px 0 22px;
                border-bottom: 1px solid #e5e7ed;
            }

            &__flex-container {
                border-bottom: none;
            }
        }

        .MuiTab {
            &__button {
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
            }
        }

        .ScrollButton {
            position: absolute;
            z-index: 3;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 0;
            width: 36px;
            padding-top: 4px;

            &.on-right {
                right: 1px;
            }

            &.on-left {
                left: 1px;
            }

            &__icon {
                height: 8px;
                color: #7b7979;

                &.on-right {
                    transform: rotate(90deg);
                }

                &.on-left {
                    transform: rotate(270deg);
                }
            }
        }

        .Tabs__wrapper {
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
        }

        .Tabs__panel {
            padding-top: 0px !important;
        }
    }

    &--scrollable {
        @extend .Tabs--base;

        .Tabs__wrapper {
            overflow-y: scroll;
        }
    }

    &--horizontal-scroll {
        @extend .Tabs--base;

        .MuiTabs-scroller.MuiTabs-fixed {
            overflow-x: auto !important;
            @include thin-scrollbar;
        }
        .MuiTabs__root {
            padding: 0;
        }
    }
}
