@import '@assets/theme';

.TableFeedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(85, 85, 85, 0.1);
    margin: 0 20px;
    padding-top: 16px;

    svg {
        color: $color_soft_black;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $color_soft_black;
        margin-top: 16px;
        margin-bottom: 6px;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color_opaque_soft_black;
    }

    &__icon {
        width: 48px;
        height: 48px;
        padding: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeeee;
        border-radius: 20px;
    }

    &__loading {
        width: 30%;
        max-width: 120px;
        max-height: 100%;
        margin: 0 auto;
    }

    &__loading-title {
        font-size: 1.25em;
        font-weight: 600;
        color: $color_opaque_soft_black;
        text-align: center;
    }
}
