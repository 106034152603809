@import '@assets/theme';

.TagsContent {
    &__tag-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        gap: 8px;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
    }

    &__tags-not-found {
        flex: auto;
        align-items: center;
    }

    &__collapse-container {
        padding-top: 20px;
    }

    &__collapse-toggle {
        display: flex;
        cursor: pointer;
        user-select: none;
        justify-content: center;
        transition: transform 0.3s ease-in-out;
        transform: rotate(180deg);

        &--closed {
            transform: none;
        }

        &:after {
            content: '';
            padding: 24px;
            position: absolute;
            left: -18px;
            top: -12px;
        }

        svg {
            width: 12px;
            color: $color_opaque_soft_black;
        }

        &:hover {
            svg {
                color: $color_soft_black;
            }
        }
    }
}

.TagsSearchBar {
    &__search-wrapper {
        width: 300px;
        margin-top: 8px;
    }

    &__divider {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;
    }

    &__heading {
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
    }
}
