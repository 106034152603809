.SearchCriteriaResults {
    &__button {
        display: flex;
        align-items: center;
        padding: 0 16px;
    }

    &__criteria {
        margin-top: 20px;
    }
}
