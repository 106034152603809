@import '@assets/theme';

.ExportModal {
    min-height: 160px;

    @include breakpoint(small) {
        width: 80vw;
    }

    &__body {
        padding-top: 16px;
        text-align: center;
    }

    &__text {
        margin-bottom: 16px;
    }

    &__loading {
        width: 80px;
        margin: 0 auto;
    }

    &__footer {
        height: 72px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 24px 0 24px;
        margin-top: 16px;
        margin-left: -24px;
        margin-right: -24px;
        gap: 8px;

        border-top: 1px solid rgba(85, 85, 85, 0.1);
        border-radius: 0px 0px 10px 10px;
    }
}
