@import '@assets/theme';

.RatingStars {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &__stars-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
}
