@import '@assets/theme';

.ComparisonBarChart {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    background-color: #f2fbff;
    padding: 20px;
    border-radius: 4px;
    max-width: 320px;
    column-gap: 20px;

    &__bar-container {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        min-height: 232px;
        row-gap: 4px;
        width: 40px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &.no-gap {
            row-gap: 0px;
        }
    }

    &__upper-bar {
        cursor: pointer;
        background: linear-gradient(180deg, #006ef5 0%, #00b4ff 100%);
        &.dimmed {
            background: #d9d9d9;
        }
    }

    &__lower-bar {
        cursor: pointer;
        background: linear-gradient(180deg, #1dfeec 0%, #07c7f9 60%, #00b4ff 100%),
            linear-gradient(162deg, #97f6f6 17.25%, #66e3c8 79.73%), linear-gradient(180deg, #97f6f6 0%, #66e3c8 100%),
            #00ebf5;
        &.dimmed {
            background: #d9d9d9;
        }
    }

    &__labels {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__label-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__title {
        color: $color_opaque_soft_black;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }
}
