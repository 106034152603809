@import '@assets/theme';

.PurchasePotential {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include breakpoint(large) {
        flex-direction: column;
    }

    &__total-container {
        background: #f2fbff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 154px;
        padding: 16px 0px;
        border-radius: 12px;
        margin-top: 30px;
        @include breakpoint(large) {
            width: 100%;
        }
    }

    &__charts-container {
        display: flex;
        justify-content: space-between;
        flex: 1;

        @include breakpoint(medium) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__chart-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
        border-right: 1px solid $color_rgba_gray;

        &:last-child {
            border-right: none;
        }

        @include breakpoint(medium) {
            border-right: none;
            border-top: 1px solid $color_rgba_gray;
            padding-top: 20px;
        }
    }

    &__charts {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: space-evenly;
    }
}
