.DonutChart {
    overflow: visible;
    transform-origin: center;
    width: 100%;
    max-width: 200px;
    aspect-ratio: 1 / 1;

    .gradient-path {
        cursor: pointer;
    }

    path:not(.gradient-path) {
        transform-origin: center;
        opacity: 0.7;
    }

    .non-hovered-slice {
        fill: #d9d9d9;
    }
}
