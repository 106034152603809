@import '@assets/theme';

.HorizontalStackedBarChart {
    $root: &;

    &__title {
        font-size: 12px;
        font-weight: 500;
        color: $color_opaque_soft_black;
    }

    &__chart-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;

        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: $color_soft_black;
    }

    &__bars-wrapper {
        pointer-events: none;
        display: flex;
        background-image: linear-gradient(90deg, #006ef5, #14e5f3);
        height: 12px;
    }

    &__bar {
        flex: 0 1 auto;
        pointer-events: auto;
        height: 12px;

        background-color: transparent;
        border: 2px solid white;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            inset: -4px;
            border: 4px solid white;
            cursor: pointer;
            background-color: transparent;
            border-radius: 20px;
        }

        &.dimmed:before {
            background-color: #d9d9d9;
        }
    }

    &__tooltip {
        display: flex;
        column-gap: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #a7a6a6;

        .description {
            font-weight: 600;
            color: white;
        }
    }
}

.GroupedHorizontalStackedBarChart {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}
