@import '@assets/theme';

$disabled_color: #bdbdbd;

.SwitchPill {
    border-radius: 8px;
    box-shadow:
        inset 0 0 0 50px rgba(#000, 0.02),
        inset 0 0 0 50px #fff;
    border: 1px solid rgba(#555, 0.12);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    line-height: normal;
    color: $color_soft_black;
    margin-bottom: 10px;
    padding: 6px 10px;
    background-origin: border-box;
    background-color: #fafafa;
    cursor: pointer;
    user-select: none;

    > input {
        display: none;
    }

    $root: &;

    &:hover {
        box-shadow: inset 0 0 0 50px #fff;
    }

    &.is-disabled {
        cursor: not-allowed;

        border: 1px solid $color_rgba_gray;
        background-color: white;
        box-shadow: none;
        color: $disabled_color;

        &:hover {
            box-shadow: inset 0 0 0 50px #ebebeb;
        }
    }

    &.is-purple:not(.is-disabled) {
        &.is-active,
        &:hover {
            background-image: getGradient('purple');
        }

        &.is-active {
            box-shadow:
                inset 0 0 0 50px rgba($tertiary_magenta, 0.02),
                inset 0 0 0 50px #fff;
        }
    }

    &.is-blue:not(.is-disabled) {
        &.is-active,
        &:hover {
            background-image: linear-gradient(0deg, $tertiary_bolt_blue, $tertiary_blue);
        }

        &.is-active {
            box-shadow:
                inset 0 0 0 50px rgba($tertiary_bolt_blue, 0.04),
                inset 0 0 0 50px #fff;
        }
    }

    &.is-orange:not(.is-disabled) {
        &.is-active,
        &:hover {
            background-image: getGradient('orange');
        }

        &.is-active {
            box-shadow:
                inset 0 0 0 50px rgba($tertiary_tangerine, 0.04),
                inset 0 0 0 50px #fff;
        }
    }

    &.is-green:not(.is-disabled) {
        &.is-active,
        &:hover {
            background-image: getGradient('green');
        }

        &.is-active {
            box-shadow:
                inset 0 0 0 50px rgba($tertiary_forest_green, 0.04),
                inset 0 0 0 50px #fff;
        }
    }

    &.is-red:not(.is-disabled) {
        &.is-active,
        &:hover {
            background-image: getGradient('red');
        }

        &.is-active {
            box-shadow:
                inset 0 0 0 50px rgba($tertiary_ferrari_red, 0.04),
                inset 0 0 0 50px #fff;
        }
    }

    &__check-icon {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: -7px;
        right: -6px;
        border-radius: 10px;
        opacity: 0;

        @each $name, $value in $gradient__colors {
            .is-#{$name} & {
                background-image: getGradient($name);
            }
        }

        @include checkbox-icon;

        .is-active & {
            opacity: 1;
        }
    }

    &__padlock-icon {
        margin-left: 8px;
        justify-self: flex-end;
        flex-shrink: 0;
        color: $disabled_color;
    }

    &__rating-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        width: 100%;
    }
}
