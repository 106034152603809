@import '@assets/theme';

.Map {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    position: relative;

    &__container {
        height: 100%;
        width: 100%;
        border-radius: 12px;
    }
}

.ZoomControl {
    position: absolute;
    right: 10px;
    bottom: 24px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    width: 28px;
    height: 55px;
    border-radius: 8px;
    background-color: #eeeeee;
    row-gap: 1px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));

    &__button {
        user-select: none;
        cursor: pointer;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: white;
        font-weight: 600;

        &:hover {
            .ZoomControl__button-icon {
                color: black;
            }
        }
    }

    &__button-icon {
        color: $color_soft_black;
    }
}
