$grid__columns: (
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
);

@mixin list-columns {
    @each $name, $value in $grid__columns {
        &__col-#{$name} {
            grid-template-columns: repeat(#{$value}, 1fr);
        }
    }
}

.SwitchList {
    display: grid;
    grid-auto-rows: 1fr;
    gap: 16px;
    width: 100%;

    @include list-columns;

    &__list-item {
        position: relative;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        min-height: 62px;
        padding: 10px 8px;
        flex-shrink: 0;
        margin-bottom: 0;

        &--small {
            min-height: 42px;
        }

        &--bold {
            font-weight: 600;
        }
    }

    &__icon {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__subtitle {
        font-weight: 500;
        color: #6e6c6c;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
}
