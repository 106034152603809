@import '@assets/theme';

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: $color_soft_black;
    }
}
