@import '@assets/theme';

.DropdownMenu {
    &__container {
        border-radius: 12px;
        border: 1px solid rgba(85, 85, 85, 0.1);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
        margin-top: 8px;
    }

    &__list {
        padding: 12px 0;
        background-color: white;
    }

    &__divider {
        height: 1px;
        background-color: rgba(85, 85, 85, 0.1);
		margin: 12px 0;
    }
}

.DropdownMenuItem {
    &__container {
        display: flex;
        column-gap: 12px;
        border-radius: 6px;
        padding: 8px 12px;
        margin: 0 12px;
        transition: background-color 0.2s;

        color: $color_soft_black;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;

        &:hover {
            background: rgba(85, 85, 85, 0.04);
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;

        svg {
            color: #918f8f;
        }
    }
}

.KebabMenuIcon {
    position: relative;
    color: $color_opaque_soft_black;

    &:hover {
        color: $color_soft_black;
    }

    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 17px);
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}
