@import '@assets/theme';

.Inputs {
    &__options-list {
        padding: 0;
        max-height: 40vh;
        .Inputs__option {
            padding: 8px 10px;
            border-radius: 6px;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;

            &:hover {
                background: rgba(85, 85, 85, 0.04);
            }

            &[aria-selected='true'] {
                color: #908f8f;
                background: none;
                &:hover {
                    background: rgba(85, 85, 85, 0.04);
                }
            }
        }
    }

    &__popper {
        padding-top: 8px;
    }

    &__dropdown {
        padding: 12px;

        background: white;
        border: 1px solid $color_rgba_gray;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
        border-radius: 10px;

        &--spaced {
            margin-top: 8px;
        }
    }

    &__select-placeholder {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 14px;
        right: 32px;

        color: #908f8f;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__clear-icon {
        display: none;
        cursor: pointer;
        height: auto;
        margin: 0;
        padding: 5px;

        &--visible {
            display: flex;
        }
    }

    &__loader {
        @extend %loading-spinner;
    }
}

.NoResultsFound {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        opacity: 0.65;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $color_soft_black;
        margin: 6px 0;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $color_opaque_soft_black;
        text-align: center;
    }
}

.SelectIcon {
    height: 10px;
    width: 10px;
    flex-shrink: 0;
    position: absolute;
    right: 14px;
    top: calc(50% - 5px);

    color: $color_soft_black;
    transform: rotateZ(0deg);
    transition: all 0.3s ease;
    pointer-events: none;
    user-select: none;

    &--open {
        transform: rotateZ(180deg);
    }
}
