@import '@assets/theme';

.TableFilterDropdown {
    display: flex;
    width: 394px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    border-radius: 12px;
    background: #fff;

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        span {
            color: $color_opaque_soft_black;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
        }
    }

    &__search {
        width: 100%;
    }

    &__options-container {
        display: flex;
        max-height: 340px;
        flex: 1 0 0;
        flex-direction: column;
        align-self: stretch;
        border-radius: 10px;
        border: 1px solid rgba(85, 85, 85, 0.1);
    }

    &__options-list {
        padding: 8px 6px;
        overflow-y: scroll;
        @include thin-scrollbar;
    }

    &__option-title {
        text-transform: capitalize;
    }

    &__option-checkbox {
        height: auto;
    }

    &__options-footer {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        border-top: 1px solid rgba(85, 85, 85, 0.1);
        background: rgba(0, 0, 0, 0.02);

        color: $color_soft_black;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
    }

    &__footer {
        display: flex;
        width: 100%;
        gap: 10px;

        .Button {
            width: auto;
            flex-grow: 1;
            flex-basis: 50%;
        }
    }
}
