@import '@assets/theme';

.SocialLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__heading-description {
        column-gap: 4px;
        color: $color_opaque_soft_black;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.24px;
        text-transform: uppercase;
    }
}
