@import '@assets/theme';

.Drawer {
    .MuiPaper-root:not(.MuiDrawer-paperAnchorTop):not(.MuiDrawer-paperAnchorBottom) {
        max-width: 95%;
    }

    $col_gap: 32px;

    &__header {
        padding: 32px 32px 24px 32px;
        border-bottom: 1px solid $color_rgba_gray;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        column-gap: $col_gap;
    }

    &__title {
        display: flex;
        align-items: center;
        width: calc(100% - 24px - $col_gap);
        gap: 12px;
    }

    &__close {
        flex-basis: 24px;
    }

    &__content {
        flex-basis: 100%;
    }

    &__body {
        height: 100%;
        padding: 32px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__footer {
        padding: 16px 32px;
        border-top: 1px solid $color_rgba_gray;
        justify-content: flex-end;
    }
}
