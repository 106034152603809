.HierarchyIndicator {
    position: relative;
    $padding_left: 20px;

    &__item {
        position: relative;
        width: 100%;
        padding-left: $padding_left;
        overflow: auto;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            border-left: 1px solid #e8e9e9;
        }

        &:last-child {
            &:before {
                bottom: auto;
                height: 8px;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 12px;
            height: 14px;
            border-bottom-left-radius: 8px;
            border-bottom: 1px solid #e8e9e9;
            border-left: 1px solid #e8e9e9;
        }
    }
}
