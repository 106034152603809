@import '@assets/theme';

.Chip {
    $root: &;
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    height: 28px;
    border-radius: 8px;
    padding: 6px 10px;
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid $color_rgba_gray;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    user-select: none;

    &:hover {
        background: white;
        box-shadow: $box_shadow_primary;

        #{$root}__icon svg > path {
            stroke: $color_soft_black;
        }
    }

    &--tag {
        &.is-purple {
            &.is-active,
            &:hover:not(.is-gradient) {
                border-color: $tertiary_magenta;
            }

            &.is-active {
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_magenta, 0.02),
                    inset 0 0 0 50px #fff;
            }

            &.is-gradient:hover {
                background-image: $gradient_purple;
                box-shadow: inset 0 0 0 50px #fff;
                border: 1px solid $color_rgba_gray;
            }

            &.is-active.is-gradient {
                background-image: $gradient_purple;
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_purple, 0.04),
                    inset 0 0 0 50px #fff;
                border: 1px solid $color_rgba_gray;
            }
        }

        &.is-blue {
            &.is-active,
            &:hover {
                border-color: $tertiary_bolt_blue;
            }

            &.is-active {
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_bolt_blue, 0.04),
                    inset 0 0 0 50px #fff;
            }
        }

        &.is-orange {
            &.is-active,
            &:hover:not(.is-gradient) {
                border-color: $tertiary_tangerine;
            }

            &.is-active {
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_tangerine, 0.04),
                    inset 0 0 0 50px #fff;
            }

            &.is-gradient:hover {
                background-image: getGradient('orange');
                box-shadow: inset 0 0 0 50px #fff;
                border: 1px solid $color_rgba_gray;
            }

            &.is-active.is-gradient {
                background-image: getGradient('orange');
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_tangerine, 0.04),
                    inset 0 0 0 50px #fff;
                border: 1px solid $color_rgba_gray;
            }
        }

        &.is-green {
            &.is-active,
            &:hover {
                border-color: $tertiary_forest_green;
            }

            &.is-active {
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_forest_green, 0.04),
                    inset 0 0 0 50px #fff;
            }
        }

        &.is-red {
            &.is-active,
            &:hover {
                border-color: $tertiary_ferrari_red;
            }

            &.is-active {
                box-shadow:
                    inset 0 0 0 50px rgba($tertiary_ferrari_red, 0.04),
                    inset 0 0 0 50px #fff;
            }
        }

        &.is-table-tag {
            height: auto;
            padding: 5px 10px;
            min-height: 28px;
            flex-shrink: 1;
            flex-grow: 1;

            #{$root}__name {
                white-space: normal;
                text-align: center;
                width: 100%;
            }
        }
    }

    @include breakpoint(small) {
        flex-shrink: 1;
        height: auto;

        font-size: 13px;
        line-height: auto;
    }

    &__description {
        color: rgba(35, 32, 32, 0.65);
    }

    &__name {
        color: $color_soft_black;
        text-decoration: none;
        white-space: nowrap;
    }

    &__icon {
        cursor: pointer;
        display: flex;

        svg > path {
            stroke: #6e6c6c;
        }
    }

    &__dot {
        height: 8px;
        width: 8px;
        border-radius: 3px;
        flex-shrink: 0;

        &.is-purple {
            background-color: $tertiary_magenta;
        }

        &.is-blue {
            background-color: $tertiary_bolt_blue;
        }

        &.is-orange {
            background-color: $tertiary_tangerine;
        }

        &.is-green {
            background-color: $tertiary_lime_green;
        }

        &.is-red {
            background-color: $tertiary_ferrari_red;
        }
    }

    &__highlight {
        background: #d2f6e8;
    }
}
