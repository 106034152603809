@import '@assets/theme';

.OperatorResultsSummary {
    &__segmentation-section {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__charts {
        display: flex;
        flex-wrap: wrap;
        padding-top: 16px;
        gap: 16px;
    }

    @mixin chart-box {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 24px;

        border: 1px solid $color_rgba_gray;
        border-radius: 12px;
        padding: 24px 20px 20px 20px;
    }

    &__segments {
        @include chart-box;
        flex: 1 0 600px;

        @include breakpoint(large) {
            flex-basis: 450px;
        }

        @include breakpoint(medium) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__chart-legend-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__legend {
        display: flex;
        flex-direction: column;
        padding-right: 30px;
    }

    &__legend-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    &__legend-details {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }

    &__legend-color {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        border-radius: 4px;
    }

    &__legend-label {
        color: $color_opaque_soft_black;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &__annual-sales {
        @include chart-box;
        flex: 1;
        margin-right: auto;

        @include breakpoint(large) {
            flex-basis: 450px;
        }

        @include breakpoint(medium) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__units-comparison {
        @include chart-box;
        flex: 0 0 260px;
        @include breakpoint(small) {
            flex-basis: 100%;
        }
    }

    &__donut-charts {
        @include chart-box;
        flex: 0 0 400px;
        gap: 10px;
        @include breakpoint(small) {
            flex-basis: 100%;
        }
    }

    &__cuisines {
        @include chart-box;
        flex: 1;

        @include breakpoint(medium) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__group-count {
        text-transform: uppercase;
        color: $color_opaque_soft_black;
        font-size: 13px;
        position: relative;
        top: 10px;
        margin: 15px;
        display: flex;
        font-weight: 500;
        align-items: center;
        justify-content: center;

        &::before,
        &::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid $color_opaque_soft_black;
            opacity: 0.2;
            margin: 0 8px;
        }
    }
}
