@import '@assets/theme';

.Layout {
    display: flex;

    @include breakpoint(medium) {
        flex-direction: column;
    }

    &__loader {
        height: 100%;
        width: 20%;
        min-width: 120px;
        max-width: 160px;
        display: flex;
        align-items: center;
        margin: 0 auto;
    }
}
