@import '@assets/theme';

.OperatorResultsMap {
    &__map-container {
        height: 400px;
        width: 100%;
        position: relative;
    }

    $h-padding: 20px;

    &__info-box-body {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0px;
        background: $color_soft_black;
        border-radius: 12px;

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 0px;
            left: 50%;
            bottom: 0;
            border: 10px solid transparent;
            border-bottom: 0;
            border-top: 10px solid $color_soft_black;
            transform: translate(-50%, 100%);
        }
    }

    &__info-box-header {
        width: 300px;
        display: flex;
        justify-content: space-between;
        padding: 0px $h-padding;
        margin-bottom: 16px;
    }

    &__info-box-header-title {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-left: 12px;

        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
    }

    $grey-text: #a7a6a6;

    &__info-box-header-subtitle {
        color: $grey-text;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
    }

    &__info-box-content {
        display: flex;
        width: 100%;
        padding: 0px $h-padding;

        color: #fff;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
    }

    &__info-box-address {
        display: flex;
        width: 100%;
        height: 31px;
        padding: 0px $h-padding;
        margin-top: 4px;

        color: $grey-text;
        font-size: 13px;
        font-weight: 500;

        svg {
            margin-right: 6px;
        }
    }

    &__info-box-category {
        display: flex;
        width: 100%;
        padding: 0px $h-padding;
    }

    &__info-box-footer {
        display: flex;
        padding: 12px $h-padding 0px $h-padding;
        width: 300px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid rgba(85, 85, 85, 0.3);
        margin-top: 16px;
    }

    &__info-box-link {
        text-decoration: none;
        color: $color_primary_aqua;
        margin-left: auto;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
    }

    &__loading-container {
        position: absolute;
        inset: 0;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 99999999;
    }
}
