@import '@assets/theme';

.GroupedVerticalProgressBar {
    display: flex;
    overflow-x: scroll;

    @include thin-scrollbar;
    padding-left: 10px;
    justify-content: space-between;
    @include breakpoint(medium) {
        gap: 25px;
    }
}
