@import '@assets/theme';

* {
    font-family: 'Montserrat', sans-serif;
}

body {
    height: 100vh;
}

.heading-1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}

.heading-2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
}

.heading-3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
}

.heading-4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
}

.heading-5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.body-1 {
    font-weight: 500;
    color: $color_soft_black;
    font-size: 14px;
    line-height: 18px;
}

.body-2 {
    font-weight: 500;
    color: $color_opaque_soft_black;
    font-size: 13px;
    line-height: 19px;

    &--black {
        color: $color_soft_black;
    }
}

.link {
    font-weight: 500;
    color: $tertiary_compliant_aqua;
    font-size: 13px;
    line-height: 16px;
}

.flex {
    display: flex;

    &-col {
        flex-direction: column;
    }

    &-row {
        flex-direction: row;
    }

    &-wrap {
        flex-wrap: wrap;
    }
}
