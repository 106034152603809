@import '@assets/theme';

.SavedSearch {
    $root: &;

    &__section {
        box-shadow:
            0px 1px 2px rgba(0, 0, 0, 0.02),
            0px 2px 16px rgba(0, 0, 0, 0.04);
        background-color: transparent;
        border-radius: 24px;

        .Widget--table {
            box-shadow: none;
        }
    }

    &__table-header {
        display: flex;
        padding: 25px 25px 0px 25px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
    }

    &__table-body {
        background-color: white;
        border-radius: 0 0 24px 24px;
    }
}

.NoSavedSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    svg {
        opacity: 0.65;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $color_soft_black;
        margin: 6px 0;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $color_opaque_soft_black;
    }
}

.TableMobile {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    text-decoration: none;

    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $color_soft_black;
    padding: 16px 8px;
    border-bottom: 1px solid $color_rgba_gray;

    strong {
        font-weight: 600;
    }

    &__row {
        display: flex;
        div {
            flex: 1;
        }
    }

    &__name {
        cursor: pointer;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding: 24px 12px 0 12px;
        gap: 10px;
        align-self: stretch;
    }

    &__sort {
        padding: 8px;

        svg {
            transform: rotate(180deg);
        }

        &:hover {
            cursor: pointer;

            svg {
                color: $tertiary_compliant_aqua;
            }
        }

        &--desc {
            svg {
                transform: rotate(0deg);
            }
        }
    }
}
