@import '@assets/theme';

.Dropdowns {
    $height: 46px;

    &__search {
        height: $height;
        display: flex;
        flex-direction: row;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__search-input {
        width: 100%;
        padding: 8px 12px 8px 42px;
        border: none;
        background-color: #fafafa;
        color: $color_soft_black;
        font-weight: 500;
        font-size: 13px;

        &:focus-visible {
            outline: none;
        }

        &::placeholder {
            color: #8e8d8d;
        }
    }

    &__search-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        height: 14px;
        color: $color_soft_black;
    }

    $gap: 8px;
    $left-slot-width: 14px;

    &__option {
        margin-left: calc(#{$left-slot-width} + #{$gap});
    }

    &__selected-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gap;
        font-weight: 600;
    }

    &__list {
        padding: 12px;
        overflow-y: scroll;

        @include thin-scrollbar;
    }
}
