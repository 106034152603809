@import '@assets/theme';

$switch-width: 34px;
$switch-height: 22px;
$switch-indent-x: 4px;
$switch-indent-y: 3px;
$switch-translate: $switch-width - ($switch-height - 2 * $switch-indent-y) - 2 * $switch-indent-x;

.Toggle {
    $root: &;
    position: relative;
    height: 30px;

    display: flex;
    align-items: center;

    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;

    &.is-disabled {
        pointer-events: none;
        color: $color_opaque_soft_black;
    }

    &__input {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        pointer-events: none;
        opacity: 0;
        margin: 0;
    }

    &__label {
        font-size: 15px;
    }

    &__icon {
        $size: 20px;
        width: $size;
        height: $size;
        margin-right: 12px;
        position: relative;
        color: rgba($color_light_gray, 0.4);
        transition:
            color 300ms,
            background-color 300ms,
            border-color 300ms;
        background-color: #fff;
        box-sizing: border-box;

        #{$root}:hover & {
            color: $color_primary_aqua;
        }

        #{$root}.is-checked & {
            color: $color_primary_aqua;
        }

        #{$root}.is-disabled & {
            color: rgba($color_light_gray, 0.1);
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            transition: opacity 300ms;
        }

        &.is-checkbox {
            border: 2px solid currentColor;
            border-radius: 6px;
            background-color: transparent;
            flex-shrink: 0;
            transition: transform 0.2s ease;

            #{$root}.is-checked & {
                border-color: transparent;
                background-color: currentColor;
            }

            #{$root}:active & {
                transform: scale(0.9);
            }

            @include checkbox-icon;

            &:before,
            &:after {
                opacity: 0;
                transition: opacity 300ms;

                #{$root}.is-checked & {
                    opacity: 1;
                }
            }
        }

        &.is-radio {
            border-radius: 50%;
            background-color: transparent;
            border: 2px solid;

            &:before {
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border-radius: 50%;
                background-color: currentColor;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }

            #{$root}:active & {
                transform: scale(1.1, 0.9);
                animation: none;
            }

            #{$root}:not(:active) & {
                animation: radioBounce 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }

            #{$root}.is-checked & {
                &:before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }

        &.is-switch {
            width: $switch-width;
            height: $switch-height;
            background-color: currentColor;
            border-radius: $switch-height * 0.5;

            #{$root}:not(.is-checked):hover & {
                color: rgba($color_light_gray, 0.5);
            }

            &:before {
                width: $switch-height - 2 * $switch-indent-y;
                height: $switch-height - 2 * $switch-indent-y;
                top: $switch-indent-y;
                left: $switch-indent-x;
                background-color: #fff;
                border-radius: 50%;
                transition: transform 400ms cubic-bezier(0.4, 0.0, 0.2, 1),
                          border-radius 400ms cubic-bezier(0.4, 0.0, 0.2, 1);

                #{$root}.is-checked & {
                    transform: translateX($switch-translate);
                    animation: switchBounceLeft 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
                    transform-origin: right center;
                }

                #{$root}:not(.is-checked) & {
                    transform: translateX(0);
                    animation: switchBounceRight 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
                    transform-origin: left center;
                }

                #{$root}:hover:not(.has-interacted):not(.is-checked) & {
                    transform: scaleX(1.2);
                    border-radius: 40% 60% 60% 40% / 50%;
                    transform-origin: left center;
                }

                #{$root}:hover:not(.has-interacted).is-checked & {
                    transform: translateX($switch-translate) scaleX(1.2);
                    border-radius: 60% 40% 40% 60% / 50%;
                    transform-origin: right center;
                }
            }
        }

    }
}

@keyframes switchBounceLeft {
    0% { 
        transform: translateX(0) scaleX(1);
        border-radius: 50%;
        transform-origin: left center;
    }
    40% { 
        transform: translateX(0) scaleX(1.8);
        border-radius: 40% 60% 60% 40% / 50%;
        transform-origin: left center;
    }
    70% { 
        transform: translateX($switch-translate) scaleX(1.1);
        border-radius: 40% 60% 60% 40% / 50%;
        transform-origin: left center;
    }
    85% { 
        transform: translateX($switch-translate) scaleX(0.65);
        border-radius: 50%;
        transform-origin: right center;
    }
    100% { 
        transform: translateX($switch-translate) scaleX(1);
        border-radius: 50%;
        transform-origin: center;
    }
}

@keyframes switchBounceRight {
    0% { 
        transform: translateX($switch-translate) scaleX(1);
        border-radius: 50%;
        transform-origin: right center;
    }
    40% { 
        transform: translateX($switch-translate) scaleX(1.8);
        border-radius: 60% 40% 40% 60% / 50%;
        transform-origin: right center;
    }
    70% { 
        transform: translateX(0) scaleX(1.1);
        border-radius: 60% 40% 40% 60% / 50%;
        transform-origin: right center;
    }
    85% { 
        transform: translateX(0) scaleX(0.65);
        border-radius: 50%;
        transform-origin: left center;
    }
    100% { 
        transform: translateX(0) scaleX(1);
        border-radius: 50%;
        transform-origin: center;
    }
}

@keyframes radioBounce {
    0% {
        transform: scale(1.1, 0.9);
    }
    50% {
        transform: scale(0.9, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}




