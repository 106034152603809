@import '@assets/theme';

.Collapse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;

    &__default {
        cursor: pointer;
    }

    &__title {
        flex: 0 1 auto;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $color_soft_black;
    }

    &__collapsed-content {
        flex: 1 0 auto;

        &--expanded {
            visibility: hidden;
        }

        &--bottom-arrow {
            display: none;
        }
    }

    &__toggle {
        position: relative;
        flex: 0 1 auto;
        cursor: pointer;
        user-select: none;
        transition: transform 0.3s ease-in-out;

        &--closed {
            transform: rotate(180deg);
        }

        &:after {
            content: '';
            padding: 24px;
            position: absolute;
            left: -18px;
            top: -12px;
        }

        svg {
            width: 12px;
            color: $color_opaque_soft_black;
        }

        &:hover {
            svg {
                color: $color_soft_black;
            }
        }
    }

    &__collapsible {
        height: 23px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #66deb2;
        border-radius: 100px;
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -9px;
        z-index: 3;
        cursor: pointer;

        &--collapsed {
            transform: rotate(180deg);
        }
    }

    &__double-arrow {
        transform: rotate(90deg);
    }
}
