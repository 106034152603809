@import '@assets/theme';

.OperatorResultsTableMobile {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    text-decoration: none;

    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $color_soft_black;

    strong {
        font-weight: 600;
    }

    &__row {
        display: flex;
        div {
            flex: 1;
        }
    }

    &__name {
        cursor: pointer;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
        gap: 10px;
        align-self: stretch;
    }

    &__loading-container {
        margin-top: 16px;
    }

    &__sort {
        padding: 8px;

        svg {
            transform: rotate(180deg);
        }

        &:hover {
            cursor: pointer;

            svg {
                color: $tertiary_compliant_aqua;
            }
        }

        &--desc {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }

    &__divider {
        width: 100%;
        height: 1px;
        margin: 16px 0;
        background-color: $color_rgba_gray;
    }
}
