@import '@assets/theme';

.OperatorProfileLocation {
    margin-top: 12px;
    margin-bottom: 40px;

    @include breakpoint(medium) {
        margin-bottom: 48px;
    }

    &__container {
        display: flex;
        column-gap: 32px;
        row-gap: 24px;

        @include breakpoint(medium) {
            flex-direction: column;
        }
    }
    &__data-section {
        display: flex;
        width: 60%;
        flex-direction: column;
    }

    &__map-section {
        display: flex;
        height: 260px;
        width: 100%;
        max-width: 600px;

        @include breakpoint(medium) {
            min-width: unset;
            max-width: unset;
        }
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px 60px;

        @include breakpoint(medium) {
            grid-template-columns: 1fr;
        }
    }

    &__info-column {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        color: #918f8f;
    }

    &__media-icons {
        display: flex;
        align-items: center;
        column-gap: 12px;
        margin-top: auto;
        padding-top: 8px;

        svg {
            max-height: 16px;
            width: auto;
        }
    }

    &__separator {
        width: 100%;
        height: 1px;
        background-color: $color_rgba_gray;
        margin: 24px 0;
    }

    &__delivery-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 11px;
        border: 1px solid $color_rgba_gray;

        svg {
            width: 24px;
        }
    }
}

.WorkingHours {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &__day {
        display: flex;
        align-items: center;
        opacity: 0.65;
    }
}

.ThirdPartyDelivery {
    display: flex;
    gap: 8px;

    &__icon {
        max-width: 100%;
        max-height: 100%;
    }

    &__item {
        height: 32px;
        width: 32px;
        border-radius: 5px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color_rgba_gray;
    }
}
