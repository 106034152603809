@import '@assets/theme';

.TerritoryFavoritesCriteria {
    margin-bottom: 10px;
}

.TerritorySwitchList {
    margin-top: 10px;
    padding-right: 5px;

    &__header-container {
        display: flex;
        align-items: center;
    }

    &__title {
        margin-right: 16px;
    }

    &__custom {
        .SwitchList__list-item {
            justify-content: flex-start;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 12px;
            flex: 0;
        }

        .SwitchList__subtitle {
            flex: 1;
            display: flex;
            font-size: 11px;
            align-items: flex-start;
        }

        .SwitchList__icon {
            margin-top: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .SwitchList__list-item.SwitchList__add-territory {
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: 600;
        }

        .SwitchPill.is-green.SwitchList__list-item.SwitchList__add-territory {
            box-shadow: none;
            background-color: white;
            &:hover {
                box-shadow: inset 0 0 0 50px #fff;
            }
        }
    }

    &__preview-button {
        margin-top: auto;
        margin-left: auto;
        margin-right: 8px;
        text-transform: lowercase;
    }
}
