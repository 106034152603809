@import '@assets/theme';

.ProfileTags {
    &__content {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }

    &__group-container {
        display: flex;
        flex-direction: column;
    }

    &__group-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}
