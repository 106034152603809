@import '@assets/theme';

.Toggle-button {
    background-color: rgb(239, 241, 247);
    width: fit-content;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    position: relative;
    border: 1px solid #dee0e7;

    &__option {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        padding: 12px 13px 12px 14px;
        color: rgba(35, 32, 32, 1);
        font-weight: 500;

        &--icon {
            display: inline-block;
        }

        &--selected {
            background-color: white;
            border: 1px solid #dddddd;
            margin: -1px;
        }

        &:not(&--selected) {
            cursor: pointer;
            background-color: rgb(239, 241, 247);
            opacity: 65%;
            border: none;
        }
    }
}
