@import '@assets/theme';

.Sidebar {
    display: flex;
    width: 288px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 38px 32px;
    border-right: 1px solid rgba(85, 85, 85, 0.08);
    transition-property: width, padding, align-items;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    position: relative;

    &__content {
        display: flex;
        flex-direction: column;

        .Sidebar__logo {
            margin-bottom: 32px;
            height: 34px;
            width: 100%;
            position: relative;
            display: flex;

            svg.logo {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.3s linear;
                transition-delay: 300ms;

                &.hidden {
                    opacity: 0;
                    display: none;
                }
            }
        }
    }

    &__admin-separator {
        width: 100%;
        height: 1px;
        background-color: rgba(85, 85, 85, 0.1);
		margin-bottom: 36px;
    }

    &__collapsible {
        height: 18px;
        width: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 7px;
        right: calc(-10px - 32px);
        background-color: #66deb2;
        border-radius: 100px;
        cursor: pointer;
        transition: all 0.7s;
        z-index: 3;
    }

    &__item {
        margin-bottom: 36px;
        position: relative;
    }

    &__link {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
            content: '';
            position: absolute;
            width: calc(100% + 8px);
            height: 40px;
            left: -12px;
            cursor: pointer;
        }

        svg {
            opacity: 1;
            stroke: #232020a6;
            flex-shrink: 0;
        }

        span {
            font-size: 15px;
            letter-spacing: 0.1px;
            line-height: 16px;
            color: #232020a6;
            text-decoration: none;
            margin-left: 12px;
            font-weight: 500;
        }

        &:hover,
        &--active {
            span {
                color: $color_soft_black;
            }

            svg > * {
                stroke: $color_soft_black;
            }
        }

        &--active {
            svg > * {
                stroke: transparent;
            }

            &:hover {
                svg > * {
                    stroke: transparent;
                }
            }
        }
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        line-height: 16px;
        color: #232020a6;
        padding-top: 16px;
        border-top: 1px solid rgba(85, 85, 85, 0.08);
        cursor: pointer;

        & > div {
            display: flex;
            align-items: center;

            .MuiAvatar-root {
                height: 32px;
                width: 32px;
                font-size: 12px;
                font-weight: 600;
                color: $color_soft_black;
            }

            span {
                margin-left: 12px;
                font-weight: 500;
            }
        }

        svg {
            margin-right: -1px;
            margin-bottom: 1px;
        }
    }

    &__tooltip {
        padding: 4px;
    }

    &--collapsed {
        width: 88px;
        flex-shrink: 0;
        padding: 38px 22px;

        .Sidebar__content {
            .Sidebar__logo > svg.logo {
                left: 5px;
                transition-delay: 0ms;
            }
        }

        .Sidebar__link {
            margin-left: 11.5px;
            flex-shrink: 0;

            &:before {
                width: 100%;
                left: 0;
            }

            span {
                display: none;
            }
        }

        .Sidebar__avatar {
            margin-left: 5.5px;
        }

        .Sidebar__collapsible {
            right: calc(-11px - 22px);

            svg {
                transform: rotateY(180deg);
            }
        }
    }

    @include breakpoint(medium) {
        height: 66px;
        width: 100%;
        padding: 16px 19px;
        overflow-y: hidden;
        border-right: none;
        border-bottom: 1px solid $color_rgba_gray;
        transition: height 0.6s ease-in-out;

        &--open {
            height: 100vh;
            background-color: white;
        }

        &-mobile-icon {
            display: flex;
            flex-direction: column;
            width: 18px;
            cursor: pointer;

            span {
                background: #000;
                border-radius: 10px;
                height: 3px;
                margin: 1px 0;
                transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
            }
            span:nth-of-type(1) {
                width: 75%;
            }

            span:nth-of-type(2) {
                width: 100%;
            }

            span:nth-of-type(3) {
                width: 75%;
            }

            &--open {
                span:nth-of-type(1) {
                    width: 100%;
                    transform-origin: bottom;
                    transform: rotatez(45deg) translate(3px, 3px);
                }

                span:nth-of-type(2) {
                    transform-origin: top;
                    transform: rotatez(-45deg);
                }

                span:nth-of-type(3) {
                    transform-origin: bottom;
                    width: 0%;
                }
            }
        }

        &__content {
            padding-left: unset;
            margin-left: 5px;

            .Sidebar__logo {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                > svg.logo {
                    position: static;
                    margin-left: -6px;
                }
            }
        }

        &__item {
            margin-bottom: 40px;
        }

        &__link {
            margin-left: 3px;
        }

        &__avatar {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.1px;

            svg {
                margin-right: -6px;
                margin-bottom: 0;
            }
        }
    }
}

.SidebarSkeleton {
    &__nav-item {
        margin-bottom: 36px;
        margin-right: 12px;
    }

    &__mobile-icon {
        row-gap: 2px;
    }
}

.MuiTooltip-popper.Tooltip--popper {
    .Tooltip--wrapper {
        right: 8px;
    }
}
