@import '@assets/theme';

.Territories {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        padding-left: 8px;
    }

    &__header-buttons {
        display: flex;
        column-gap: 16px;
    }

    &__country-selector {
        display: flex;
        align-items: center;
        margin-top: 2px;
        cursor: pointer;

        &.disabled {
            cursor: default;
        }
    }

    &__search {
        max-width: 300px;

        @include breakpoint(medium) {
            max-width: 100%;
        }
    }

    &__kebab-menu {
        position: relative;
        color: $color_opaque_soft_black;

        &:hover {
            color: $color_soft_black;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 17px);
            left: calc(50% - 15px);
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }

    &__info-icon {
        transform: translate(2px, 4px);
    }

    &__favorite {
        margin-left: 40px;
        scale: 1.5;

        &:hover path {
            fill: transparent;
            stroke: url(#star_gradient);
            stroke-width: 1.5;
        }
    }
}
