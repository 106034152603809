@import '@assets/theme';

.SearchCriterionDropdown {
    display: flex;
    padding: 6px 10px;
    align-items: center;
    gap: 8px;
    height: 28px;

    border-radius: 8px;
    border: 1px solid rgba(85, 85, 85, 0.2);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);

    &__name {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        color: $color_soft_black;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    &__dropdown {
        max-width: 475px;
        max-height: 294px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-shrink: 0;
        align-self: stretch;
        gap: 6px;
        padding: 12px;

        overflow-y: scroll;

        @include thin-scrollbar;
    }

    &__divider {
        width: 1px;
        height: 14px;
        background: rgba(85, 85, 85, 0.1);
    }

    &__delete-button {
        cursor: pointer;
    }

    &__dropdown-icon {
        transition: transform 0.2s ease-in-out;

        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__dot {
        height: 8px;
        width: 8px;
        border-radius: 3px;
        flex-shrink: 0;

        &.is-purple {
            background-color: $tertiary_magenta;
        }

        &.is-blue {
            background-color: $tertiary_bolt_blue;
        }

        &.is-orange {
            background-color: $tertiary_tangerine;
        }

        &.is-green {
            background-color: $tertiary_lime_green;
        }

        &.is-red {
            background-color: $tertiary_ferrari_red;
        }
    }
}
