@import '@assets/theme';

.SaveSearchModal {
    width: 368px;
    min-height: 160px;

    @include breakpoint(small) {
        width: 80vw;
    }

    &__body {
        padding-top: 16px;
    }

    &__footer {
        height: 72px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 16px 24px 0 24px;
        margin-top: 24px;
        margin-left: -24px;
        margin-right: -24px;
        gap: 8px;

        border-top: 1px solid rgba(85, 85, 85, 0.1);
        border-radius: 0px 0px 10px 10px;

        .Button {
            width: auto;
        }
    }

    &__success {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    &__success-icon {
        width: 48px;
        height: 48px;
        border-radius: 20px;
        background: $color_primary_aqua;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
