@import '@assets/theme';

.TerritoryDrawer {
    width: 600px;

    &__group-name {
        color: $color_opaque_soft_black;
        font-size: 16px;
        font-weight: 500;
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
    }

    &__criterion {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }

    &__criterion-name {
        color: $color_soft_black;
        font-size: 18px;
        font-weight: 600;

        &--not-searchable {
            color: $tertiary_red;
        }
    }

    &__criterion-grid {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        column-gap: 40px;
    }

    &__criterion-value {
        color: $color_soft_black;
        font-size: 16px;
        font-weight: 500;
    }

    &__footer {
        color: $tertiary_red;
    }
}
