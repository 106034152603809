@import '@assets/theme';

.LocationContent {
    &__input-container {
        width: 47%;
        max-width: 340px;
        position: relative;

        @include breakpoint(medium) {
            width: 100%;
            max-width: none;
        }
    }

    hr {
        margin: 24px 0;
        border: 0;
        border-bottom: 1px solid rgba(85, 85, 85, 0.1);
    }
}
