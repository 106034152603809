$breakpoints: (
    small: 600px,
    medium: 900px,
    large: 1200px,
);

@mixin breakpoint($name) {
    @if not map-has-key($breakpoints, $name) {
        @error "Warning: `#{$name}` is not a valid breakpoint name.";
    } @else {
        @media (max-width: map-get($breakpoints, $name)) {
            @content;
        }
    }
}
