.ChooseYourCriteria {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    color: #8b8b8f;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    &__icon {
        position: relative;
        transform: translateX(-50%);
        margin-bottom: 32px;
    }
}
