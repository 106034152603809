@import '@assets/theme';

.Criteria {
    margin-bottom: 6px;
    padding-left: 14px;
    display: flex;
    position: relative;

    &__and-indicator {
        height: 15px;
        width: 28px;
        background-color: white;

        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #908f8f;
        text-align: center;
        text-transform: uppercase;

        position: absolute;
        top: calc(50% - 7.5px);
        left: 0;
        z-index: 1;
    }

    &__chips {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 10px;
        margin-left: 10px;
        margin-bottom: 10px;

        &--last {
            margin-bottom: 0;
        }
    }
}
