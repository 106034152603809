@import '@assets/theme';

.UpdateSearchModal {
    width: 368px;
    min-height: 160px;

    @include breakpoint(small) {
        width: 80vw;
    }

    &__body {
        padding-top: 16px;
    }

    &__footer {
        height: auto; 
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; 
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px 0 24px;
        margin-top: 24px;
        margin-left: -24px;
        margin-right: -24px;
        gap: 8px;
    
        border-top: 1px solid rgba(85, 85, 85, 0.1);
        border-radius: 0px 0px 10px 10px;
    
        .Button {
            flex: 1 0 auto; 
            margin: 5px;
            min-width: 120px; 
            max-width: 100%; 
        }
    }
}
