@import '@assets/theme';

.TooltipComponent {
    .Tooltip {
        &--wrapper {
            padding: 6px 14px;
            border-radius: 8px;
            background-color: $color_soft_black;
            color: white;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
            position: relative;
        }

        &--arrow {
            color: $color_soft_black;
        }
    }
}

.InformationTooltip {
    &__title {
        color: #66deb2;
    }
}
