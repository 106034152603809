@import '@assets/theme';

.Breadcrumbs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    &__return-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;

        svg {
            height: 8px;
            margin-right: 12px;
            color: #777679;
            transform: rotate(270deg);
        }
    }

    &:hover {
        .Breadcrumbs__return-icon {
            svg {
                color: $color_soft_black;
            }
        }
    }

    &__separator {
        color: #706e6e;
        font-size: 13px;
        position: relative;
        top: 1px;
        margin: 0 8px;
    }
}
