@import '@assets/theme';

.ErrorPage {
    display: flex;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
        text-align: center;
    }

    &__icon-container {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        border-radius: 20px;
        background: $color_rgba_gray;
    }

    &__description {
        color: #8b8b8f;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 24px;
    }
}
