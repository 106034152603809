@import '@assets/theme';

.MenuSection {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-bottom: 12px;
    scroll-margin-top: 4px;
    margin-top: 20px;

    @include breakpoint(medium) {
        margin-top: 5px;
    }

    &__header {
        display: flex;
        padding: 4px 16px;
        align-items: center;
        border-radius: 8px;
        background-color: $color_primary_aqua;
        color: $color_soft_black;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-transform: capitalize;
    }

    &__items-container {
        display: flex;
        padding-left: 16px;
        flex-direction: column;
    }

    &__item {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        padding: 8px 0px 12px 0px;
        border-bottom: 1px solid $color_rgba_gray;
    }

    &__item-text {
        display: flex;
        row-gap: 4px;
        flex-direction: column;
        flex: 1;
    }

    &__item-price {
        display: flex;
        color: $color_soft_black;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
    }
}
