@import '@assets/theme';

.PageTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &__title {
        color: $color_soft_black;
        line-height: 34px;
        font-size: 28px;
        font-weight: 700;
    }
}
