@import '@assets/theme';

.Map {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    position: relative;

    &__content {
        height: 100%;
        width: 100%;
        border-radius: 12px;
    }

    &__marker-label {
        text-shadow: 0 0 2px white;
        position: absolute;
        left: 20px;
        bottom: 40%;
    }

    &__mode-button {
        position: absolute;
        bottom: 14px;
        right: 14px;
        z-index: 1;

        min-width: 100px;
        display: flex;
        padding: 8px 12px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        user-select: none;
        cursor: pointer;
    }
    &__map-section-animation {
        height: 360px;
        width: 100%;
        max-width: 600px;
        position: absolute;
        top: 50%;
        padding: 0px;
        border-width: 0px;
        margin: 0px;

        @include breakpoint(medium) {
            min-width: unset;
            max-width: unset;
        }
    }
}
