@import '@assets/theme';

.DisplayDrawer {
    &--header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 16px;

        &__separator {
            height: 16px;
            width: 0px;
            border: 1px solid rgba(85, 85, 85, 0.3);
        }

        .Button {
            width: auto;
        }

        @include breakpoint(small) {
            justify-content: center;
        }
    }

    &--footer {
        display: flex;
        justify-content: flex-end;
        column-gap: 8px;

        .Button {
            width: auto;
            color: black;
        }
    }

    &--body {
        display: flex;
        flex-direction: column;

        &__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: $color_soft_black;
            margin-bottom: 24px;
        }

        .TogglingOptions {
            display: flex;
            flex-direction: column;
            flex: 1;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 24px;

            &--toggle-row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 8px;
                column-gap: 40px;
            }
        }

        .ColumnsSection {
            display: flex;
            flex-direction: row;
            padding-top: 32px;

            &--column {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            &--row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__subtitle {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 18px;
                    color: $color_soft_black;
                    opacity: 0.65;
                }
            }
        }

        .ColumnsGroup {
            &--group {
                margin-bottom: 24px;
            }

            &__name {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: uppercase;
                color: $color_soft_black;
                opacity: 0.5;
                margin-bottom: 12px;
            }
        }
    }
}
