@import '@assets/theme';
@import '@components/utility/Inputs/Inputs.styles.scss';

.QuickSearch {
    $root: &;

    display: flex;
    width: 100%;

    &--large {
        #{$root}__root {
            padding: 0 20px 0 28px;
            height: 72px;
            margin: 0 20px;
        }

        #{$root}__input {
            font-size: 20px;
            line-height: 16px;
        }

        #{$root}__start-adornment {
            svg {
                height: 20px;
                width: 20px;
            }
        }

        #{$root}__end-adornment {
            cursor: pointer;
            padding: 8px;
            height: auto;
            display: none;

            svg {
                height: 14px;
                width: 14px;
            }

            &--visible {
                display: flex;
            }
        }
    }

    &__loader {
        @extend %loading-spinner;
    }
}

.QuickSearchDropdown {
    border-radius: 12px 12px 0 0;
    padding: 0;

    &__tab {
        display: flex;
    }

    &__tab-counter {
        display: inline-flex;
        align-items: center;
        margin-left: 6px;
        padding: 1px 6px;
        background: $color_rgba_gray;
        border-radius: 4px;

        font-weight: 600;
        font-size: 10px;
        line-height: 13px;
        color: $color_light_gray;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 22px;
        border-top: 1px solid $color_rgba_gray;
        border-radius: 0 0 12px 12px;
        background-color: #fafafa;

        &-country {
            display: flex;
            column-gap: 8px;
            align-items: center;

            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #8e8d8d;
            text-transform: uppercase;
        }
    }
}

.SearchCategory {
    display: flex;
    flex-direction: column;

    &__title {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #908f8f;
        padding: 8px 22px 12px 22px;
        margin-top: 8px;
        text-transform: uppercase;
    }

    &__list {
        padding: 0 12px 12px 12px;
        border-bottom: 1px solid #f5f5f5;
    }

    &__item {
        display: flex;
        column-gap: 12px;
        align-items: center;
        padding: 8px 10px;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
            background: rgba(85, 85, 85, 0.04);

            .SearchCategory__item-logo {
                border: 1px solid $color_primary_aqua;

                svg {
                    color: $color_soft_black;
                }
            }
        }
    }

    &__item-logo {
        box-sizing: content-box;
        width: 32px;
        height: 32px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        background: white;

        border: 1px solid $color_rgba_gray;
        border-radius: 11px;

        svg {
            color: #918f8f;
        }
    }

    &__item-image {
        width: 28px;
        height: 28px;
        margin: auto;
        border-radius: 11px;
    }

    &__item-details {
        display: flex;
        column-gap: 12px;
        align-items: center;

        @include breakpoint(small) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__item-title {
        font-weight: 500;
        font-size: 13px;
        color: $color_soft_black;
        text-transform: capitalize;
    }

    &__item-subtitle {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 11px;
        line-height: 15px;
        color: #706e6e;
    }

    &__all-results {
        width: fit-content;
        padding: 8px 10px 4px 10px;
        text-transform: lowercase;
        cursor: pointer;
    }
}

.ListResults {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 16px 22px;
        border-radius: 12px 12px 0px 0px;
        border-bottom: 1px solid $color_rgba_gray;
        background: white;
    }

    &__header-wrapper {
        opacity: 0.5;

        &:hover {
            opacity: 0.8;
        }
    }

    &__header-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $color_soft_black;
    }

    &__header-icon {
        rotate: -90deg;
        height: 16px;
        margin-right: 8px;
        color: $color_soft_black;
    }
}
